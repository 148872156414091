// var isIE = /*@cc_on!@*/ false || !!document.documentMode;
// !isIE && require("./asb");
// console.log("*** checkBrowser", isIE);

// if (
//   window.matchMedia(app.breakpoints.mobile).matches == false &&
//   window.matchMedia(app.breakpoints.tablet).matches == false
// ) {
//   // desktop
// } else {
//  // mobile
// }
// import 'bootstrap/js/dist/dropdown'

const app = {
  breakpoints: {
    mobile: "(max-width: 767px)",
    tablet: "(min-width: 768px) and (max-width: 991px)",
    desktop: "(min-width: 992px)",
  },

  events: {},

  functions: {
    cookieConsent: function () {
      $(document).ready(function () {
        $("#cookieConsent").cookieConsent();
      });
    },
    checkCookie: function () {
      $(document).ready(function () {
        if (!$.cookie("cookiesConsentDate")) {
          $("#cookieConsent").css("bottom", "0%");
        }
      });
    },
  },

  plugins: {
    mask: function () {
      var SPMaskBehavior = function (val) {
        return val.replace(/\D/g, "").length === 11
          ? "(00) 00000-0000"
          : "(00) 0000-00009";
      };
      var SPMaskBehaviornoDDD = function (val) {
        return val.replace(/\D/g, "").length === 11
          ? "00000-0000"
          : "0000-00009";
      };
      var spOptions = {
        onKeyPress: function (val, e, field, options) {
          field.mask(SPMaskBehavior.apply({}, arguments), options);
        },
      };
      var spOptionsnoDDD = {
        onKeyPress: function (val, e, field, options) {
          field.mask(SPMaskBehaviornoDDD.apply({}, arguments), options);
        },
      };

      var CPFCNJPMaskBehavior = function (val) {
        return val.replace(/\D/g, "").length > 11
          ? "00.000.000/0000-00"
          : "000.000.000-009999";
      };
      var cpfcnpjOptions = {
        onKeyPress: function (val, e, field, options) {
          field.mask(CPFCNJPMaskBehavior.apply({}, arguments), options);
        },
      };

      $('[data-mask="date"]').mask("00/00/0000");
      $('[data-mask="telephone"]').mask("(00) 0000-0000");
      $('[data-mask="celphone"]').mask(SPMaskBehavior, spOptions);
      $('[data-mask="telcelphone"]').mask(SPMaskBehavior, spOptions);
      $('[data-mask="telcelphonenoddd"]').mask(
        SPMaskBehaviornoDDD,
        spOptionsnoDDD
      );
      $('[data-mask="cpfcnpj"]').mask(CPFCNJPMaskBehavior, cpfcnpjOptions);
      $('[data-mask="cpf"]').mask("000.000.000-00", {
        reverse: true,
      });
      $('[data-mask="rg"]').mask("00.000.000-0", {
        reverse: true,
      });
      $('[data-mask="cnpj"]').mask("00.000.000/0000-00", {
        reverse: true,
      });
      $('[data-mask="money"]').mask("000.000.000.000.000,00", {
        reverse: true,
      });
      // app.functions.maskPlate();
      $('[data-mask="cep"]').mask("00000-000");
    },

    slick: {},

    aos: function () {
      AOS.init({
        duration: 800,
        disable: "mobile",
      });
    },
  },

  init: function () {
    app.functions.cookieConsent();
    app.functions.checkCookie();

    app.plugins.mask();
    // app.plugins.aos();
  },
};

app.init();
